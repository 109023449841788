export default {
    namespaced: true,

    state: {
        isLoading: true,
        firstLoad: true,
        loadPercentage: 0,
        isFadingOut: false,
    },

    mutations: {
        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
        SET_FIRST_LOAD(state, firstLoad) {
            state.firstLoad = firstLoad;
        },
        SET_LOAD_PERCENTAGE(state, percentage) {
            state.loadPercentage = percentage;
        },
        SET_FADING_OUT(state, isFadingOut) {
            state.isFadingOut = isFadingOut;
        }
    },

    actions: {
        showLoader({ commit, dispatch, state }) {
            if (state.firstLoad) {
                commit('SET_LOADING', true);
                let percentage = 0;
                const interval = setInterval(() => {
                    percentage++;
                    commit('SET_LOAD_PERCENTAGE', percentage);
                    if (percentage >= 100) {
                        clearInterval(interval);
                        dispatch('hideLoader'); // Вызывать hideLoader после достижения 100%
                    }
                }, 20);
            }
        },
        hideLoader({ commit }) {
            commit('SET_FADING_OUT', true);
            setTimeout(() => {
                commit('SET_LOADING', false);
                commit('SET_FADING_OUT', false);
                commit('SET_FIRST_LOAD', false); // Обновляем firstLoad здесь, а не в showLoader
            }, 1000); // Убедитесь, что это время совпадает с CSS анимацией
        }
    }

};
