import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/bootstrap.min.css?v=1.0';
import './assets/css/style.css?v=1.0';

const app = createApp(App);

app.use(store);
app.use(router);

app.mount('#app');