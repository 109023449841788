import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/TemplateOne/HomeView.vue')
    },
    {
      path: '/race/:id',
      name: 'race',
      component: () => import('../views/TemplateOne/RaceView.vue')
    },
    {
      path: '/race/test/:id',
      name: 'race_test',
      component: () => import('../views/TemplateOne/MapsView.vue')
    }
  ]
})

export default router
