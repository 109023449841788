<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition :name="transitionName">
        <component :is="Component" />
      </transition>
    </router-view>

    <div v-if="isLoading" class="preloader" :class="{ 'fade-out': isFadingOut }">
      <div class="count">{{ loadPercentage }}%</div>
    </div>

  </div>
</template>

<script>
import {computed, onMounted} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'App',
  setup() {
    const route = useRoute();
    const store = useStore();

    const transitionName = computed(() => {
      if (route.path.startsWith('/race')) {
        return 'slide-right';
      } else {
        return 'slide-left';
      }
    });

    onMounted(async () => {
      store.dispatch('preloader/showLoader');
    });

    return {
      transitionName,
      isLoading: computed(() => store.state.preloader.isLoading),
      loadPercentage: computed(() => store.state.preloader.loadPercentage),
      isFadingOut: computed(() => store.state.preloader.isFadingOut),
    };
  }
};
</script>
